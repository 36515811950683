<template>
    <div id="interface">
        <div class="admin_main_block">
            <div class="user_temp_title">API接口</div>
            <div class="admin_table_main">
                <div class="page_main">
                    <p>
                        API调用方法详解<br><br>
                        开放平台（POP）的API是基于HTTP协议来调用的，开发者（ISV）可以直接使用POP的协议来封装HTTP请求进行调用，以下主要是针对自行封装HTTP请求进行API调用的原理进行详细解说。<br>
                        <img src="@/assets/img/14.png" alt=""><br><br><br>
                    </p>
                    <p style="text-align: center;">
                        <span style="color: rgb(255, 255, 255);">
                            <a href="https://newApizza.net/pro/#/project/c6a3ca274dca07ec02aeb7b9d414939c/browse" target="_blank" style="font-weight: bold; font-size: x-large; background-color: rgb(70, 172, 200);">接口对接介绍文档　　点击查看</a>
                        </span>
                    </p>
                    <br>
                    <p>
                        <span style="font-size: large;">
                            API链接网址 https://newApizza.net/pro/#/project/c6a3ca274dca07ec02aeb7b9d414939c/browse
                        </span>
                    </p><br>
                    <p style="text-align: center;">
                        <span style="font-size: x-large; font-weight: bold; background-color: rgb(255, 255, 255); color: rgb(194, 79, 74);">分站详情请咨询网站客服！！！</span>
                    </p>
                    <p style="text-align: center;"><span style="font-size: x-large;"><br></span></p>
                    <p style="text-align: center;">
                        <span style="font-size: xx-large;">
                            电&nbsp;&nbsp;&nbsp;话：
                            <span>13665768848&nbsp;&nbsp;</span>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            
        }
    },
    created(){},
    mounted(){},
    watch: {},
    methods: {}
}
</script>

<style lang="scss" scoped>
#interface{
    .admin_main_block{
        background: #fff;
        width: 100%;
        box-sizing: border-box;
        min-height: 855px;
        border-radius: 4px;
        padding: 20px;
        .user_temp_title{
            margin: auto;
            padding-left: 20px;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 20px;
            height: 25px;
            margin-bottom: 20px;
            font-weight: bold;
        }
    }
}
</style>